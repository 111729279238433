<template>
  <v-container>
    <v-row align="center">
      <!-- Left Section (Text Content) -->
      <v-col xl="5" lg="5" md="5" cols="12">
        <div class="section-title ptb--120">
          <h2 class="heading-title">Schon mal von PRISM gehört?</h2>
          <p class="description">Sourcen war noch nie so einfach.</p>
          <v-btn href="https://prism.deals"  target="_blank" color="#fd4766" dark>
            Jetzt ansehen
          </v-btn>
        </div>
      </v-col>

      <!-- Right Section (Charts/Images) -->
      <v-col xl="7" lg="7" md="7" cols="12">
        <div class="image-section">
          <!-- First Item (Mockup or Chart) -->
          <img
            ref="glowImage"
            class="glow-image"
            :class="{ 'visible': isVisible }"  
            src="../../assets/images/about/cta-dashboard.png"
            alt="Mockup 1"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>



<style scoped>
.glow-image {
  border: 2px solid transparent; /* Anfangszustand ohne Glow */
  padding: 10px;
  box-shadow: none; /* Keine Box-Shadow am Anfang */
  border-radius: 15px;
  transition: all 0.5s ease-in-out; /* Übergang für den Glow-Effekt */
}

.glow-image.visible {
  border: 2px solid var(--v-primary-base); /* Glow aktivieren */
  box-shadow: 0 0 30px rgba(253, 71, 102, 0.2); /* Der Glow-Effekt */
}
</style>



<script>
export default {
  data() {
    return {
      isVisible: false, // Trackt, ob das Bild sichtbar ist
    };
  },
  mounted() {
    // Intersection Observer erstellen
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.isVisible = true; // Setze isVisible auf true, wenn das Bild sichtbar ist
          observer.unobserve(this.$refs.glowImage); // Stoppt die Beobachtung, wenn sichtbar
        }
      });
    });

    // Beobachte das Bild
    observer.observe(this.$refs.glowImage);
  },
};
</script>
