<template>
  <!-- Start Service Area  -->
  <v-row class="service-main-wrapper" align="stretch">
    <!-- Start Single Service  -->
    <v-col
      lg="4"
      md="6"
      sm="6"
      cols="12"
      v-for="(service, i) in serviceContent"
      :key="i"
      class="d-flex flex-column"
    >
      <div class="text-center service service__style--2 flex-grow-1">
        <div class="service">
          <div class="icon" v-html="iconSvg(service.icon)"></div>
          <div class="content">
            <h3 class="heading-title">{{ service.title }}</h3>
            <p>
              {{ service.desc }}
            </p>
          </div>
        </div>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </v-row>
  <!-- End Service Area  -->
</template>

<script>
import feather from "feather-icons";
export default {
  data() {
    return {
      serviceContent: [
        {
          icon: "sliders",
          title: "Toolgestützte Markenanalyse",
          desc: "Mit modernen Analysetools finden wir die besten Marken und Produkte für Amazon, basierend auf Markttrends und Kundenbedürfnissen.",
        },
        {
          icon: "terminal",
          title: "Effizienz durch Automatisierung",
          desc: "Automatisierte Systeme optimieren unsere Prozesse, von der Rechnungserstellung bis zur Lieferung, um Bestellungen schnell und zuverlässig abzuwickeln.",
        },
        {
          icon: "compass",
          title: "Schnelle Anpassungsfähigkeit",
          desc: "Dank unserer schlanken Strukturen reagieren wir flexibel und effizient auf neue Herausforderungen und passen uns dynamischen Marktanforderungen problemlos an.",
        },
      ],
    };
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
