import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'
import Missing from '../views/404.vue'
import Demo from '../views/Demo.vue'


Vue.use(VueRouter)

const routes = [{
        path: '/',
        component: Demo,
        meta: {
            title: 'r7commerce - Startseite'
        }
    },

    
    
    {
        path: '/impressum',
        name: 'Impressum',
        meta: {
            title: 'r7commerce - Impressum'
        },
        component: () =>
            import ('../views/Impressum.vue')
    },
    {
        path: '/datenschutzerklaerung',
        name: 'Datenschutzerklärung',
        meta: {
            title: 'r7commerce - Datenschutzerklärung'
        },
        component: () =>
            import ('../views/Datenschutzerklaerung.vue')
    },
    {
        path: '*',
        component: Missing,
        meta: {
            title: 'r7commerce - 404'
        },
    }
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0

        if (to.hash) {
            scrollTo = to.hash
        } else if (savedPosition) {
            scrollTo = savedPosition.y
        }

        return goTo(scrollTo)
    },
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    next()
})


export default router