<template>
  <footer class="footer-area footer-style-01 bg_color--6">
    <div class="im-call-to-action-area im-separator">
      
    </div>
    <div class="footer-wrapper ptb--70">
      <v-container class="container">
        <v-row>
          <v-col lg="4" md="4" sm="6" cols="12">
            <div class="ft-text">
              <div class="logo">
                <a href="/"><img :src="logo" alt="brand image"/></a>
              </div>
              <p>
                Copyright © {{ new Date().getFullYear() }}
                <a href="/">r7commerce</a> All rights reserved.
              </p>
            </div>
          </v-col>
          <v-col
            lg="2"
            offset-xl="1"
            md="6"
            sm="6"
            cols="12"
            class="mt_mobile--40"
          >
            <div class="footer-link">
              <h4>Nützliche Links</h4>
              <ul class="ft-link">
                <li> <a href="https://prism.deals">Prism</a></li>
                
              </ul>
            </div>
          </v-col>
          <v-col lg="2" md="2" sm="6" cols="12" class="mt_md--40 mt_sm--40">
            <div class="footer-link">
              <h4>Unternehmen</h4>
              <ul class="ft-link">
                <li><router-link to="/impressum">Impressum</router-link></li>
                <li><router-link to="/datenschutzerklaerung">Datenschutzerklärung</router-link></li>
              </ul>
            </div>
          </v-col>
          <v-col lg="3" md="3" sm="6" cols="12" class="mt_md--40 mt_sm--40">
            <div class="footer-link">
              <h4>Kontakt</h4>
              <ul class="ft-link">
                
                <li>
                  E-Mail: <a href="mailto:info@r7commerce.de">info@r7commerce.de</a>
                </li>
                <li>Telefon: <a href="tel:+4915679442464">+49 156 79442464</a></li>
              </ul>
              <div class="social-share-inner mt--20">
                <ul
                  class="social-share social-style--2 d-flex justify-content-start liststyle mt--15"
                >
                  <li v-for="(social, i) in socialList" :key="i">
                    <a :href="social.url" target="_blank">
                      <i class="fab" :class="social.icon"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </footer>
</template>

<script>
  export default {
    data() {
      return {
        logo: require("../../assets/images/logo/logo-light.png"),
        socialList: [
          
          {
            icon: "fa-discord",
            url: "https://discord.gg/jEWZ2ZjWy4",
          },
          {
            icon: "fa-instagram",
            url: "https://www.instagram.com/rmn.kmpf",
          },
        ],
      };
    },
  };
</script>
