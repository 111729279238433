<template>
  <div class="active-dark">
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->
    <!-- Start 404 Page  -->
    <div class="error-page-inner bg_color--5">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="inner">
              <h1 class="heading-title theme-gradient">404!</h1>
              <h3 class="sub-title">Seite nicht gefunden</h3>
              <span>Diese Seite existiert nicht.</span>
              <div class="error-button">
                <router-link class="btn-default" to="/"
                  >Zurück zur Startseite</router-link
                >
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End 404 Page  -->
    <FooterTwo />
  </div>
</template>

<script>
  import Header from "../components/header/Header";
  import FooterTwo from "../components/footer/Footer";
  export default {
    components: {
      Header,
      FooterTwo,
    },
    data() {
      return {
        logo: require("../assets/images/logo/logo.png"),
      };
    },
  };
</script>
