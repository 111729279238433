<template>
  <v-container>
    <v-row align="center" class="row--35">
      <v-col lg="5" md="5" cols="12">
        <div class="thumbnail">
          <slot name="thum-img"></slot>
        </div>
      </v-col>
      <v-col lg="7" md="7" cols="12">
        <div class="about-inner inner">
          <div class="section-title">
            <div class="icon" v-html="iconSvg(icon)"></div>
            <h2 class="heading-title">Unsere Geschichte, unsere Mission.</h2>
            <p class="description">
              
              Unsere Mission ist es, Ihnen hochwertige Produkte anzubieten, die den aktuellen Trends entsprechen und Ihre Erwartungen übertreffen. 
              
            </p>
            <p class="description">
              Durch den Einsatz modernster Analysetools und innovativer Technologien sind wir stets am Puls der Zeit und können Ihnen die besten Marken und Produkte präsentieren. 
              
            </p>
            <p class="description">
              
              Somit stehen Qualität, Innovation und Kundenzufriedenheit im Mittelpunkt unseres Handelns.
            </p>
            
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import feather from "feather-icons";
  export default {
    data() {
      return {
        icon: "send",
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
