<template>
  <div class="active-dark">
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Slider Area -->
    <div class="slider-wrapper">
      <SliderOne></SliderOne>
    </div>
    <!-- End Slider Area -->

    <!-- Start About Area -->
    <div class="about-area ptb--120 bg_color--5">
      <div class="about-wrapper">
        <About>
          <img
            slot="thum-img"
            class="w-100"
            src="../assets/images/about/about-1.png"
            alt="About Images"
          />
        </About>
      </div>
    </div>
    <!-- End About Area -->

    <!-- Start Service Area -->
    <div class="service-area ptb--120 bg_color--5">
      <v-container>
        <v-row class="mb--30">
          <v-col cols="12">
            <div class="text-center section-title">
              <span class="subtitle">WAS UNS AUSMACHT</span>
              <h2 class="heading-title">Unsere Stärken auf einen Blick.</h2>
              <p class="description">
                Wir kombinieren modernste Technologie und Kundennähe, 
                <br />
                um herausragende Ergebnisse zu liefern.
              </p>
            </div>
          </v-col>
        </v-row>
        <ServiceTwo />
      </v-container>
    </div>
    <!-- End Service Area -->

    

    <!-- Start Counterup Area -->
    <div class="rn-counterup-area ptb--120 bg_color--5">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center section-title">
              <span class="subtitle">WACHSTUM IN ZAHLEN</span>
              <h2 class="heading-title">Meilensteine unseres Erfolgs.</h2>
              <p class="description">
                Im Laufe der Jahre haben wir unsere Reichweite und unseren Einfluss durch Engagement und Expertise stetig erweitert.
              </p>
            </div>
          </v-col>
        </v-row>

        <CounterOne />
      </v-container>
    </div>
    <!-- End Counterup Area -->

  
   
    
    <!-- Start Footer Area  -->
    <Footer />
    <!-- End Footer Area  -->
  </div>
</template>

<script>
  import Header from "../components/header/Header";
  import SliderOne from "../components/slider/SliderOne";
  import About from "../components/about/About";
  import ServiceTwo from "../components/service/ServiceTwo";
  import CounterOne from "../components/counter/CounterOne";
  import Footer from "../components/footer/Footer";
  import Banner from "../components/slider/BannerTwo";

  export default {
    metaInfo: {
    meta: [
      {
        name: 'description',
        content: 'Ihr Partner im E-Commerce – wir stehen für höchste Qualität und Zuverlässigkeit. Profitieren Sie von unserem umfassenden Service. Erfahren Sie mehr.'
      }
    ]
  },
    components: {
      Header,
      SliderOne,
      About,
      ServiceTwo,
      CounterOne,
      Footer,
      Banner,
    },
    data() {
      return {
        logo: require("../assets/images/logo/logo.png"),
      };
    },
  };
</script>
